import React from 'react'

import styled from 'styled-components'
import classNames from 'classnames'

import { ReactComponent as MinusIcon } from '@/images/icons/minus.svg'
import { ReactComponent as PlusIcon } from '@/images/icons/plus.svg'

export const Counter = ({
  className, count, onMinus, onPlus, size 
}) => {
  const handleMinusClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    onMinus(e)
  }

  const handlePlusClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    onPlus(e)
  }

  return (
    <CounterBox className={classNames(className, { small: size === 'small' })}>
      <ControlBox onClick={handleMinusClick}>
        <MinusIcon />
      </ControlBox>

      <Count>{count}</Count>

      <ControlBox onClick={handlePlusClick}>
        <PlusIcon />
      </ControlBox>
    </CounterBox>
  )
}

const CounterBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 150px;
  height: 40px;
  background: ${props => props.theme.backgrounds.quaternary};
  border-radius: 8px;

  &.small {
    height: 24px;
    min-width: 100px;
    background: transparent;
  }
`

const ControlBox = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 4.8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.backgrounds.tertiary};
  border-radius: 8px;
  
  ${CounterBox}.small & {
    width: 24px;
    height: 24px;
    background: transparent;
    border: 1px solid #7E7E82;
    border-radius: 4.8px;

    svg {
      width: 10px;
      height: 10px;

      path {
        stroke: #7E7E82;
      }
    }
  }
`

const Count = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: ${props => props.theme.text.main};
  width: 45px;
`